import React, {useEffect} from 'react';
import './project.css';
import {useParams, useNavigate} from "react-router-dom";
import { motion } from 'framer-motion';

import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import YoutubeEmbed from '../../components/youtube-embed/youtube-embed';
import DOMPurify from 'dompurify';

const Project= ({projects}) => {
  const navigate = useNavigate();

  const selectProject = (id) => {
    navigate(`/project/${id}`);
  }

  const images = (arr) => {
    return arr.map(image => {
      return <img key={image} src={`/images/work/${image}`} className="project-image" alt={image}/>
    });
  }

 

  const [project, setProject] = React.useState(null)  
  const [nextProjectId, setNextProjectId] = React.useState(null)  
  const [prevProjectId, setPrevProjectId] = React.useState(null)  
  const [mobileLayout, setMobileLayout] = React.useState(window.innerWidth <= 950)  
  const {id} = useParams();

  useEffect(() => {
    let cleanId = parseInt(id)
    let proj = projects.find((p) => p.id === cleanId);
    setProject(proj);

    let next = (parseInt(id) + 1 > projects.length) ? 1 : parseInt(id) + 1;
    setNextProjectId(next);

    let prev = (id - 1 < 1) ? projects.length : id - 1;
    setPrevProjectId(prev);

    const handleWindowResize = () => {
      if (window.innerWidth <= 950) {
        setMobileLayout(true);
      } else {
        setMobileLayout(false);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [id,projects])
 
  return (
    <motion.div
      initial={{ 
        opacity: 0,
      }}
      animate={{ 
        opacity: 1,
      }}
      exit={{ 
        opacity: 0,
      }}
      transition={{ duration: 0.2 }}
    >
      { project &&
        <div className='project-navbar'>
          <IconButton onClick={() => selectProject(prevProjectId)}>
            <KeyboardDoubleArrowLeftIcon 
              style={{
                color:'#292929',
                width:'40px',
                height:'40px',
                cursor:'pointer'
              }}
            />
          </IconButton>
        
          <div className='project-title'>{project.name}</div>
          
          <IconButton onClick={() => selectProject(nextProjectId)}>
            <KeyboardDoubleArrowRightIcon 
              style={{
                color:'#292929',
                width:'40px',
                height:'40px',
                cursor:'pointer'
              }}
            />
          </IconButton>
        </div>
      }
      { project &&
        
        <div className='project-container'
          style={{height: `calc(100vh - 120px)`,}}
        >
          
          <div className='project-content-container'>
            <div className='image-gallery'>
              {project.youtube_url &&
                <YoutubeEmbed embedId={project.youtube_url} title={project.title}/>
              }
              {project.hero_tile_asset &&
                <img className='project-image' src={`/images/work/${project.hero_tile_asset}`} alt='project.name'/>
              }

              {mobileLayout &&
                <div className='project-description-block' 
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(project.description)
                  }} 
                />
              }

              {project.additional_assets &&
                images(project.additional_assets)
              }
            </div>
            
            {!mobileLayout &&
              <div className='project-description-block' 
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(project.description)
                }} 
              />
            }
            
          </div>
      </div>
    }
  </motion.div>
  );
}

export default Project;