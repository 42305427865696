import React, {useEffect} from "react";
import PropTypes from "prop-types";

const getWidth = () => {
  let width
  if (window.innerWidth >= 720) {
      width = 640
  } else if (window.innerWidth >= 600 && window.innerWidth < 720) {
      width = 580
  } else if (window.innerWidth >= 480 && window.innerWidth < 600) {
      width = 450
  } else if (window.innerWidth < 480) {
    width = 320
  }
  return width
}

const getHeight = () => {
  let height
  if (window.innerWidth >= 720) {
    height = 360
  } else if (window.innerWidth >= 600 && window.innerWidth < 720) {
    height = 326
  } else if (window.innerWidth >= 480 && window.innerWidth < 600) {
    height = 253
  } else if (window.innerWidth < 480) {
    height = 180
  } 
  return height
}


const YoutubeEmbed = ({ embedId, embedTitle }) => {
  
  const [videoWidth, setWidth] = React.useState(getWidth())  
  const [videoHeight, setHeight] = React.useState(getHeight())  
  
  useEffect(() => {
    const handleWindowResize = () => {
      let width = getWidth()
      setWidth(width)
      let height = getHeight()
      setHeight(height)
    };
  
    window.addEventListener('resize', handleWindowResize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [])
  
  return (
    <div className="video-container">
      <div className="video-responsive">
        <iframe
          width={videoWidth}
          height={videoHeight}
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={embedTitle}
          style={{marginBottom:'4em'}}
        />
      </div>
    </div>
    
  )
 
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;