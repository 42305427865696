import React from 'react';
import './mobile-menu.css';
import {
    NavLink
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion  } from 'framer-motion';

import { setMenuInvisible } from '../../features/menu/menuSlice';

const STAGGER = 0.3;

const sideVariants = {
    closed: {
        opacity : 0,
        left: '100vw',
        transition: {
            staggerChildren: STAGGER,
            staggerDirection: -1
        }
    },
    open: {
        opacity: 1,
        left: 0,
        transition: {
            staggerChildren: STAGGER,
            staggerDirection: 1,
            ease: "linear",
        }
    }
}

const itemVariants = {
    closed: {
        opacity: 0,
        y:60
    },
    open: {
        opacity: 1,
        y:0,
        delay: 0.3,
        duration:0.5
    }
}

const links = [
    { name: "HOME", to: "/", id: 1 },
    { name: "WORK", to: "/work", id: 3 },
    { name: "INFO", to: "/info", id: 4 }
  ]; 

const MobileMenu = () => {

    const menuVisible = useSelector((state) => state.menu.value);
    const dispatch = useDispatch();

    return (
        <AnimatePresence>
            {menuVisible && (
                <motion.aside  
                    initial={{ 
                        left: '100vw',
                        opacity : 0 
                    }} 
                    animate={{ 
                        left: '0vw',
                        opacity: 1 
                    }}
                    exit={{
                        left:'100vw',
                        opacity: 0,
                        transition: { duration:0.3}
                    }} 
                >
                    <motion.div 
                        className='mobile-menu-container'
                        initial="closed"
                        animate="open"
                        variants={sideVariants}
                    >
                        {links.map(({ name, to, id }) => (
                            <motion.div key={id} variants={itemVariants}>
                                <NavLink 
                                    className ='nav-link nav-button link' 
                                    to={to}
                                    style={({ isActive }) => ({
                                        color: isActive ? '#80dcff' : '',
                                        pointerEvents: isActive ? 'none' : 'auto'
                                    })}
                                    onClick={() => dispatch(setMenuInvisible())}
                                >
                                    {name}
                                </NavLink>
                            </motion.div>
                        ))}
                    </motion.div>
                </motion.aside>
            )}
        </AnimatePresence>
        
    );
}

export default MobileMenu;