import React from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Home from './home/home';
import Work from './work/work';
import Info from './info/info';
import Project from './project/project';
import { projectData } from "../data/config";
import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = (theme) => {
  const location = useLocation();

    return (
        <AnimatePresence mode='wait'>
            <Routes key={location.pathname} location={location}>
                <Route path="/" element={<Home />} />
                <Route path="work" element={<Work projects={projectData}/>} />
                <Route path="project/:id" element={<Project theme={theme} projects={projectData} />} />
                <Route path="info" element={<Info />} />
            </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;
